var lastScrollTop = 0;

function slideNextContent(btn,className){
    btn.click(function(event) {
        let $this = $(this);
        event.preventDefault();
        $this.toggleClass(className);
        $this.next().slideToggle();
    });
}
function showContent(btn,content,className){
    btn.click(function(event) {
        let $this = $(this);
        event.preventDefault();
        $this.toggleClass(className);
        content.fadeToggle();
    });
}

$(function(){	


	scrollW();
	window.addEventListener('scroll', function(){
		scrollW();
	})

	$('.close-res').click(function(event) {
		event.preventDefault();
		$('html').removeClass('hide-overflow');

		$('.booking-widget').stop().animate({left:'100%'},{queue:false,duration:300});
	});

	/*MOBILE MENU NAV
	****************************************************************/
	
	//Open Navigation
	$('#menu-btn').click(function(event) {
		$('html').addClass('hide-overflow');
		$('.navigation').addClass('open');
		$('.navigation').stop().animate({left:'0px'},{duration:300});
		if($('.navigation ul li a').hasClass('.item-open')){
			$(".item-open").get(0).scrollIntoView(false);
		}
	});

	$('.close-menu').click(function(event) {
		event.preventDefault();
		$('html').removeClass('hide-overflow');
		$('.navigation').removeClass('open');
		$('.navigation').stop().animate({left:'100%'},{queue:false,duration:300});
	});

	//SUBNAVIGATION

	// Open active sub-nav on load
	$('.navigation ul.submenu li .active').closest('.inner').addClass('open')
	$('.navigation ul.submenu li .active').parents().parents('li').addClass('item-open');
	$('.navigation ul.submenu li .active').closest('.sub-sub-menu').slideDown(300, function() {}).addClass('menu-open');

	//Toggle open/close on tap
	$('.navigation li a').off('click').on('click', function(event) {
		if ( $(this).parent().children(".sub-sub-menu").length > 0 ) {

            event.preventDefault();

           	if ($(this).next().hasClass('menu-open')) {
	           	$(this).closest('.inner').removeClass('open');
	            $('.navigation ul li').removeClass('item-open');
	            $(this).parent().addClass('item-open');
                $(this).next().slideUp(300, function(){
	                if($('.navigation ul li a').hasClass('active')){
						$(".item-open").get(0).scrollIntoView(false);
					} 
                }).removeClass('menu-open');	                
            } else {
	            $(this).closest('.inner').addClass('open');
	            $('.navigation ul li').removeClass('item-open');
	            $(this).parent().addClass('item-open');
                $('.sub-sub-menu.menu-open').slideUp(300).removeClass('menu-open'); 
                $(this).next().addClass('menu-open').slideDown(300, function(){
	                if($('.navigation ul li a').hasClass('active')){
						$(".item-open").get(0).scrollIntoView(false);
					}
                });
            }
        }
    });

	/*CUSTOM SCRIPTS FOR THIS SITE
	****************************************************************/

	//Prevents fancybox links from opening
	// $("a.fancybox").on("click", function(e){
	// 	e.preventDefault();
	// });

	$(".fancybox").fancybox();

	/*END OF CUSTOM SCRIPTS
	****************************************************************/
	function scrollW(){
		//check scroll down or up for header styles
		var st = window.pageYOffset || document.documentElement.scrollTop;
		
		if(st > 5){
			if (st > lastScrollTop){
				// downscroll code
				if(!document.getElementsByTagName('header')[0].classList.contains('down')){
					document.getElementsByTagName('header')[0].classList.add('down');
					document.getElementsByTagName('header')[0].classList.remove('up');

					document.getElementsByClassName('buttons')[0].classList.add('down');
					document.getElementsByClassName('buttons')[0].classList.remove('up');
				}
			} else {
				// upscroll code
				if(!document.getElementsByTagName('header')[0].classList.contains('up')){
					document.getElementsByTagName('header')[0].classList.add('up');
					document.getElementsByTagName('header')[0].classList.remove('down');

					document.getElementsByClassName('buttons')[0].classList.remove('down');
					document.getElementsByClassName('buttons')[0].classList.add('up');
				}
			}
		} else {
			if(document.getElementsByTagName('header')[0].classList.contains('up') || document.getElementsByTagName('header')[0].classList.contains('down')){
				document.getElementsByTagName('header')[0].classList.remove('up');
				document.getElementsByTagName('header')[0].classList.remove('down');

				document.getElementsByClassName('buttons')[0].classList.remove('down');
				document.getElementsByClassName('buttons')[0].classList.remove('up');
			}
		}
		lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
	}
});


if ('NodeList' in window && !NodeList.prototype.forEach) {
    console.info('polyfill for IE11');
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (let i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}

(function(){

    /*
        HOME ARROW
        $('.arrow-home').click(function(event) {
            $('body, html').animate({
                scrollTop: $(window).height()-$('header').outerHeight()*2},
                800, 'easeOutExpo');
        });
    */

    // Remove empty <p> tags created by tinymce
    const paragraph = document.querySelectorAll('p') || [];
	paragraph.forEach( e => (e.innerHTML.trim() === '') && e.parentNode.removeChild(e));


    $('.sym-slides').slick({
        accessibility: false
    });

})();